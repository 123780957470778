import React, {useState} from 'react';
import {FeedbackForm} from "./block/feedback-form/FeedbackForm";
import {Spinner} from "./block/spinner/Spinner";
import {InfoBlock} from "./block/info-block/InfoBlock";
import {Feedback, OrderInfo} from "./block/entities";

import './App.css';
import './themes/default/color.css';
import './themes/default/root.css';

const projectUuid    = process.env.REACT_APP_PROJECT_UUID    || "" // todo fail fast if project uuid is empty or undef
const apiBaseUrl     = process.env.REACT_APP_API_BASE_URL    || "" // todo fail fast if empty or undef
const feedbackSource = window.location.hostname

const urlSearchParams = new URLSearchParams(window.location.search);
// todo State.INVALID or State.NOT_FOUND if the form_uuid not provided
const formUuid = urlSearchParams.get("form_uuid") || "";

enum State {
    LOADING_FORM,
    FORM_LOADED,
    SUBMITTING_FEEDBACK,
    FEEDBACK_SUBMITTED,
    ALREADY_SUBMITTED,
}

interface AppState {
    state: State
    order?: OrderInfo
    feedback?: Feedback
}

const App = () => {
    const [state, setState] = useState({
        state: State.LOADING_FORM,
    } as AppState)

    switch (state.state) {
        case State.LOADING_FORM:
            return <Spinner
                action={() => {
                    // todo retry
                    fetch(`${apiBaseUrl}/communication/feedback/form?form_uuid=${formUuid}`)
                        .then((response: Response) => {
                            return response.json()
                        })
                        .then((body: any) => {
                            // todo check body
                            const orderInfo: OrderInfo = {
                                OrderId: body.order_id,
                                DeliveryProvider: body.delivery_provider,
                                DeliveryDate: body.delivery_date,

                                Submitted: body.submitted,
                                SubmitDate: body.submit_date,
                            }
                            if (!orderInfo.Submitted) {
                                setState({
                                    state: State.FORM_LOADED,
                                    order: orderInfo
                                })
                            } else {
                                setState({
                                    state: State.ALREADY_SUBMITTED,
                                })
                            }
                        })
                }}
            />
        case State.FORM_LOADED:
            return <FeedbackForm
                formUuid={formUuid}
                projectUuid={projectUuid}
                feedbackSource={feedbackSource}

                orderInfo={state.order!}

                submitCallback={(feedback: Feedback) => {
                    setState((prev: AppState) => {
                        return {
                            ...prev,
                            state: State.SUBMITTING_FEEDBACK,
                            feedback: feedback
                        }
                    })
                }}
            />;
        case State.SUBMITTING_FEEDBACK:
            return <Spinner action={() => {
                const body = JSON.stringify(state.feedback);
                fetch(
                    `${apiBaseUrl}/communication/feedback/form/submit`,
                    {method: "POST", body: body})
                    .then((response: Response) => {
                        if (response.ok) {
                            setState((prev: AppState) => {
                                return {
                                    ...prev,
                                    state: State.FEEDBACK_SUBMITTED,
                                }
                            })
                        }
                    })
            }}/>
        case State.FEEDBACK_SUBMITTED:
            return <InfoBlock header={"Спасибо!"} description={"Благодаря Вам мы становимся лучше!"}/>;
        case State.ALREADY_SUBMITTED:
            return <InfoBlock header={"Отзыв уже оставлен"} description={"Еще раз спасибо Вам за отзыв!"}/>;
    }
}

export default App;
