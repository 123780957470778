import React, {useEffect} from "react";
import { compose } from '@bem-react/core'
import {Spin as SpinTemplate, withSizeL, withViewDefault, withPositionCenter} from '@yandex/ui/Spin/desktop'

const Spin = compose(withSizeL, withViewDefault, withPositionCenter)(SpinTemplate)

interface OrderLoaderProps {
    action: () => void
}

const Spinner = (props: OrderLoaderProps) => {
    useEffect(() => {props.action()})
    return <Spin progress view="default" size="l" position="center"/>
}

export {Spinner}