import React, {useState} from "react";

import './RateBlock.css'

interface RateItemProps {
    value: number

    selected: boolean
    selectCallback: (rate: number) => void
    hoverCallback: (rate: number) => void
}

function getRateDescription(rate: number): string {
    switch (rate) {
        case 5: return "Отлично"
        case 4: return "Хорошо"
        case 3: return "Нормально"
        case 2: return "Плохо"
        case 1: return "Ужасно"
    }
    return ""
}

const RateItem = (props: RateItemProps) => {
    return (
        <>
            <input type="radio" name="rating" value={props.value} id={props.value.toString()} checked={props.selected}/>
            <label
                onClick={() => props.selectCallback(props.value)}
                onPointerEnter={() => props.hoverCallback(props.value)}
                onPointerLeave={() => props.hoverCallback(-1)}
            >
                <svg width="50" height="50" viewBox="0 0 40 40">
                    <path
                        d="M20.012 4.5a.465.465 0 00-.433.27l-4.598 9.79-10.576 1.423a.468.468 0 00-.382.323.48.48 0 00.122.49l7.734 7.474-1.938 10.67c-.032.18.04.362.184.47a.46.46 0 00.497.033L20 30.27l9.378 5.172a.46.46 0 00.496-.033.478.478 0 00.185-.47L28.12 24.27l7.735-7.473a.48.48 0 00.122-.49.468.468 0 00-.382-.324L25.019 14.56 20.42 4.77a.466.466 0 00-.41-.27z"
                        fill="currentColor"/>
                </svg>
            </label>
        </>
    );
}

interface RateBlockProps {
    rate: number
    selectRateCallback: (rate: number) => void

    isValid: boolean
}

const RateBlock = (props: RateBlockProps) => {
    const [tempRate, setTempRate] = useState(-1)
    const hoverCallback = (rate: number) => {setTempRate(rate)};

    let rateDescription: JSX.Element
    if (tempRate < 0 && props.rate < 0 && !props.isValid) {
        rateDescription = <div className={"feedback-rate-desc invalid"}>Выберете оценку!</div>
    } else {
        rateDescription = (
            <div className={"feedback-rate-desc"}>
                {getRateDescription(tempRate > 0 ? tempRate : props.rate)}
            </div>
        )
    }

    return (
        <div className="feedback-rate-container">
            <div className="feedback-rate-item-container">
                <RateItem value={5} selected={props.rate === 5} selectCallback={props.selectRateCallback} hoverCallback={hoverCallback}/>
                <RateItem value={4} selected={props.rate === 4} selectCallback={props.selectRateCallback} hoverCallback={hoverCallback}/>
                <RateItem value={3} selected={props.rate === 3} selectCallback={props.selectRateCallback} hoverCallback={hoverCallback}/>
                <RateItem value={2} selected={props.rate === 2} selectCallback={props.selectRateCallback} hoverCallback={hoverCallback}/>
                <RateItem value={1} selected={props.rate === 1} selectCallback={props.selectRateCallback} hoverCallback={hoverCallback}/>
            </div>
            <div className={"feedback-rate-desc"}>
                {rateDescription}
            </div>
        </div>
    );
}

export {RateBlock}
