import React from "react";
import { compose } from '@bem-react/core'
import {
    Textarea,
    withViewDefault,
    withSizeM
} from '@yandex/ui/Textarea/desktop'
import {withDebounceInput} from "@yandex/ui/withDebounceInput";

import './CommentBlock.css'

const CommentTextarea = compose(withViewDefault, withSizeM, withDebounceInput)(Textarea)

interface CommentBlockProps {
    setCommentCallback: (text: string) => void
}

const CommentBlock = (props: CommentBlockProps) => {
    return (
        <div className="feedback-comment-container">
            <div className="feedback-comment-label">
                <label>Поделитесь впечатлениями о товаре</label>
            </div>
            <CommentTextarea
                size="m"
                view="default"
                placeholder="Напишите комментарий"
                rows={5}

                forceNotifyOnBlur
                debounceTimeout={200}
                onChange={(event) => {props.setCommentCallback(event.target.value)}}
            />
        </div>
    )
}

export {CommentBlock}