import React, {useState} from "react";
import {RateBlock} from "../rate-block/RateBlock";
import {SubmitBlock} from "../submit-block/SubmitBlock";
import {Feedback, OrderInfo} from "../entities";
import {CommentBlock} from "../comment-block/CommentBlock";

interface FeedbackFormProps {
    formUuid: string
    projectUuid: string
    feedbackSource: string

    orderInfo: OrderInfo

    submitCallback: (feedback: Feedback) => void
}

const FeedbackForm = (props: FeedbackFormProps) => {
    const [feedback, setFeedback] = useState({
        FormUuid: props.formUuid,
        ProjectUuid: props.projectUuid,

        Source: props.feedbackSource,

        OrderId: props.orderInfo.OrderId,
        Rate: -1,
        Comment: "",
        BackRequest: false,
    } as Feedback)
    const [isValid, setIsValid] = useState(true)

    return (
        <div id="feedback-container">
            <div className="feedback-order-info">
                <p>
                    Заказ #{props.orderInfo.OrderId},
                    доставка: {props.orderInfo.DeliveryProvider},
                    получен {props.orderInfo.DeliveryDate}
                </p>
            </div>

            <div className="feedback-header-container">
                <div id="feedback-header">Оцените заказ</div>
                <div id="feedback-header-desc">И помогите нам стать лучше!</div>
            </div>

            <RateBlock
                rate={feedback.Rate}
                isValid={isValid}
                selectRateCallback={(rate: number) => {
                    setFeedback((prev: Feedback) => {
                        return {
                            ...prev,
                            Rate: rate
                        }
                    })
                }}
            />
            <CommentBlock setCommentCallback={(comment: string) => {
                setFeedback((prev: Feedback) => {
                    return {
                        ...prev,
                        Comment: comment
                    }
                })
            }}/>
            <SubmitBlock
                contactWithMeCallback={(value: boolean) => {
                    setFeedback((prev: Feedback) => {
                        return {
                            ...prev,
                            BackRequest: value
                        }
                    })
                }}

                submitCallback={() => {
                    if (feedback.Rate > 0) {
                        setIsValid(true)
                        props.submitCallback(feedback);
                    } else {
                        setIsValid(false)
                    }
                }}
            />
        </div>
    );
}

export {FeedbackForm}