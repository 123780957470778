import React from "react";
import './InfoBlock.css'

interface InfoBlockProps {
    header: string
    description?: string
}

const InfoBlock = (props: InfoBlockProps) => {
    return (
        <div className="feedback-submitted-container">
            <div id="feedback-header">{props.header}</div>
            {props.description ? <div id="feedback-header-desc">{props.description}</div>: ""}
        </div>
    );
}

export {InfoBlock}