import React, { useState } from 'react'
import { compose } from '@bem-react/core'
import {
    Tumbler,
    withSizeM as TumblerWithSizeM,
    withViewDefault as TumblerWithViewDefault,
} from '@yandex/ui/Tumbler/desktop'
import {
    Button,
    withSizeM as ButtonWithSizeM,
    withViewAction as ButtonWithViewAction,
} from '@yandex/ui/Button/desktop'

import './SubmitBlock.css'

const ContactWithMeTumbler = compose(TumblerWithSizeM, TumblerWithViewDefault)(Tumbler)
const SubmitButton = compose(ButtonWithSizeM, ButtonWithViewAction)(Button)

interface SubmitBlockProps {
    contactWithMeCallback: (value: boolean) => void
    submitCallback: () => void
}

const SubmitBlock = (props: SubmitBlockProps) => {
    const [checked, setChecked] = useState(false)

    return (
        <div className="feedback-submit-container">
            <SubmitButton view="action" size="m" type="submit" onClick={() => props.submitCallback()}>
                Оценить заказ
            </SubmitButton>
            <div className="feedback-submit-connect-container">
                <ContactWithMeTumbler
                    labelAfter={"Свяжитесь со мной!"}
                    view="default"
                    size="m"
                    checked={checked}
                    onChange={(event) => {
                        props.contactWithMeCallback(event.target.checked)
                        setChecked((prev) => !prev)
                    }}
                />
            </div>
        </div>
    );
}

export {SubmitBlock}